import React from 'react';
import { translate } from '../../context/translate';
import { useLanguage } from '../../context/LanguageContext';
import { Link } from "react-router-dom";

const articles = [
    {
        id: 1,
        title: "Papel do Desenvolvedor Back-End na Segurança Cibernética: Como Escrever Código Seguro",
        link: "/article/secureCode",
        dev: "https://dev.to/dimas7dev/papel-do-desenvolvedor-back-end-na-seguranca-cibernetica-como-escrever-codigo-seguro-4e3b"
    },

];

const Article = () => {
    const { language } = useLanguage();

    return (
        <div className="flex justify-center min-h-screen">
            <div className="container mx-auto p-6">
                <h1 className="py-10 text-3xl font-bold">
                    {translate('articles', language)}
                </h1>
                <div className="gap-6">
                    {articles.map(article => (
                        <div key={article.id} className="">                        
                            
                            <h2 className="text-xl font-semibold text-gray-600 hover:underline">
                           <Link
                            to={article.link}>
                                {article.title}
                            </Link>
                            </h2>
                            <a href={article.dev} target="_blank" className="text-sm text-secondary hover:underline">
                                Artigo no Dev.to
                            </a>                                                                                
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Article;
