import React from "react";

const SecureCode = () => {
  return (
    <div className="flex justify-center min-h-screen text-justify bg-gray-100">
      <div className="container mx-auto p-3 pr-44 pl-44">
        <h1 className="py-10 text-4xl font-bold text-center">
          Papel do Desenvolvedor Back-End na Segurança Cibernética: <br></br>{" "}
          Como Escrever Código Seguro
        </h1>
        <div className="gap-6">
          <p className="text-xl ">
            A segurança cibernética é um dos pilares que são fundamentais para o
            desenvolvimento de software. No ecossistema web, o desenvolvedor
            back-end desempenha um papel crucial na proteção de sistemas contra
            ameaças e vulnerabilidades. Vamos entender um pouco como escrever
            código seguro, evitando as falhas mais comuns e adotando boas
            práticas que favorecem a segurança.
          </p>
          <br></br>
          <h3 className="text-3xl font-semibold">
            Principais Ameaças de Segurança
          </h3>
          <br></br>
          <div className="mx-auto space-y-6">
            <div className="mx-auto space-y-6">
              {/* 1 - SQL Injection */}
              <div className="space-y-1">
                <p className="text-2xl font-bold">1- SQL Injection</p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Descrição:</span> Permite que o
                    invasor execute consultas maliciosas no banco de dados,
                    acarretando no vazamento de dados sigilosos.
                  </li>
                  <li>
                    <span className="font-bold">Exemplo:</span>
                  </li>
                </ul>
                <code className="block bg-gray-200 p-2 rounded">
                  SELECT * FROM users WHERE username = '' OR 1=1;
                </code>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Solução:</span> Utilize queries
                    parametrizadas ou ORM seguro.
                  </li>
                </ul>
              </div>

              {/* 2 - Cross-Site Scripting (XSS) */}
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  2- Cross-Site Scripting (XSS)
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Descrição:</span> Permite que um
                    atacante injete scripts maliciosos em páginas visualizadas
                    por outros usuários.
                  </li>
                  <li>
                    <span className="font-bold">Exemplo:</span> Um exemplo de
                    vulnerabilidade clássica de XSS acontece quando os dados
                    inseridos pelo usuário já são inseridos diretamente na
                    página web.
                  </li>
                  <li>
                    <span className="font-bold">Solução:</span> Sempre verificar
                    e modificar os dados de entrada para que sejam seguros e
                    válidos, esse processo é chamado de sanitização.
                  </li>
                </ul>
              </div>

              {/* 3 - Cross-Site Request Forgery (CSRF) */}
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  3- Cross-Site Request Forgery (CSRF)
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Descrição:</span> Engana um
                    usuário autenticado para executar ações indesejadas.
                  </li>
                  <li>
                    <span className="font-bold">Exemplo:</span> Imagine um site
                    bancário que realiza uma transferência através de uma URL,
                    caso o site não implementar as proteções contra CSRF, um
                    invasor pode criar uma página maliciosa contendo uma tag de
                    imagem que dispara essa requisição sem o conhecimento do
                    usuário.
                  </li>
                  <li>
                    <span className="font-bold">Solução:</span> Implementar
                    tokens CSRF para validação das requisições.
                  </li>
                </ul>
              </div>

              {/* 4 - Armazenamento Inseguro de Credenciais */}
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  4- Armazenamento Inseguro de Credenciais
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Descrição:</span> Guardar senhas
                    sem proteção, ou com criptografias fracas comprometem a
                    segurança do sistema.
                  </li>
                  <li>
                    <span className="font-bold">Exemplo:</span> Um invasor pode
                    explorar SQL Injection para acessar uma coluna de senha
                    armazenada em texto puro.
                  </li>
                  <li>
                    <span className="font-bold">Solução:</span> Utilizar hashing
                    com bcrypt, Argon2 ou PBKDF2.
                  </li>
                </ul>
              </div>

              {/* 5 - Insecure Direct Object References (IDOR) */}
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  5- Insecure Direct Object References (IDOR)
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Descrição:</span> Ocorre quando
                    a aplicação expõe referências diretas a objetos sem
                    verificação adequada.
                  </li>
                  <li>
                    <span className="font-bold">Exemplo:</span>
                  </li>
                </ul>
                <code className="block bg-gray-200 p-2 rounded">
                  GET /usuario?id=123
                </code>
                <p className="text-xl">
                  Sem a verificação de acesso, um usuário malicioso pode alterar
                  o ID para acessar dados de outros usuários.
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Solução:</span> Implemente
                    controles de acesso para garantir que o usuário tenha
                    permissão para acessar o recurso.
                  </li>
                </ul>
              </div>

              {/* 6 - Server Side Request Forgery (SSRF) */}
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  6- Server Side Request Forgery (SSRF)
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    <span className="font-bold">Descrição:</span> Permite que um
                    atacante force o servidor a realizar requisições para
                    endereços não autorizados.
                  </li>
                  <li>
                    <span className="font-bold">Exemplo:</span> Se sua aplicação
                    aceita uma URL fornecida pelo usuário para processar uma
                    requisição, um invasor pode direcioná-la para serviços
                    internos.
                  </li>
                  <li>
                    <span className="font-bold">Solução:</span> Valide e
                    restrinja as URLs aceitas, evitando requisições a destinos
                    não autorizados.
                  </li>
                </ul>
              </div>
            </div>
            <div className="mx-auto space-y-6">
              <h3 className="text-3xl font-semibold">
                Boas Práticas para Desenvolvedores Back-End
              </h3>
              <p className="text-xl">
                Agora que conhecemos algumas das ameaças comuns, veja boas
                práticas para aumentar a segurança de seu sistema:
              </p>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  1- Sanitização e Validação de Entrada
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    Sempre valide e sanitize dados vindos do cliente para evitar
                    injeção de código malicioso.
                  </li>
                </ul>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  2- Autenticação e Autorização Segura
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>Use autenticação baseada em tokens JWT ou OAuth2.</li>
                  <li>Nunca exponha tokens de sessão no frontend.</li>
                </ul>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  3- Gerenciamento Seguro de Sessões
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    Utilize cookies seguros com HttpOnly e Secure ativados.
                  </li>
                  <li>Implemente um tempo de expiração para sessões.</li>
                </ul>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  4- Proteção de Dados Sensíveis
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>Criptografe dados sensíveis em repouso e em trânsito.</li>
                  <li>
                    Evite expor informações de erro detalhadas no frontend.
                  </li>
                </ul>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">5- Monitoramento e Logging</p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    Utilize logs estruturados para detectar comportamentos
                    suspeitos.
                  </li>
                  <li>Monitore tentativas de login e ações sensíveis.</li>
                </ul>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  6- Princípio do Menor Privilégio
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    Garanta que cada usuário ou serviço tenha apenas as
                    permissões necessárias para realizar suas funções.
                  </li>
                </ul>
              </div>
              <div className="space-y-1">
                <p className="text-2xl font-bold">
                  7- Monitoramento Contínuo e Logging
                </p>
                <ul className="list-disc list-inside text-xl">
                  <li>
                    Implemente sistemas de monitoramento e logging para
                    acompanhar atividades suspeitas e responder rapidamente a
                    incidentes.
                  </li>
                </ul>
              </div>
              <div className="mx-auto space-y-6">
                <h3 className="text-3xl font-semibold">
                  Ferramentas e Recursos para Segurança
                </h3>
                <div className="space-y-1">
                  <ul className="list-disc list-inside text-xl">
                    <li>
                      <a
                        href="https://owasp.org/www-project-top-ten/"
                        className="text-blue-500"
                      >
                        {" "}
                        OWASP Top 10:{" "}
                      </a>{" "}
                      Guia com as principais vulnerabilidades web.
                    </li>
                    <li>
                      <a
                        href="https://dev.to/gabrielrufino/como-tornar-sua-api-express-mais-segura-com-helmet-8jp"
                        className="text-blue-500"
                      >
                        {" "}
                        Helmet.js:{" "}
                      </a>{" "}
                      Middleware para segurança em aplicativos Express.js.
                    </li>
                    <li>
                      <a
                        href="https://www.zaproxy.org/docs/"
                        className="text-blue-500"
                      >
                        {" "}
                        ZAP{" "}
                      </a>{" "}
                      e{" "}
                      <a
                        href="https://portswigger.net/"
                        className="text-blue-500"
                      >
                        {" "}
                        Burp Suite:{" "}
                      </a>{" "}
                      Ferramentas para testar a segurança de aplicações web.
                    </li>
                  </ul>
                </div>
              </div>
              <div className="mx-auto space-y-6">
                <h3 className="text-3xl font-semibold">Conclusão</h3>
                <p className="text-xl">
                  A segurança deve ser uma preocupação constante para qualquer
                  desenvolvedor back-end. Seguir boas práticas e estar atento a
                  vulnerabilidades comuns pode prevenir ataques e garantir
                  sistemas mais robustos. Adotar uma mentalidade "Security
                  First" é essencial para o sucesso de qualquer aplicação web.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecureCode;
