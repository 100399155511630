import React from 'react';
import '../../tailwind.css';
import profilePhoto from '../../images/o peso do pensamento.jpeg';
import { useLanguage } from '../../context/LanguageContext';
import { translate } from '../../context/translate';

const About = () => {
  const { language } = useLanguage();

  return (
    <div className="flex flex-wrap px-6 py-4 mt-16 sm:px-10 relative">
      <div className="w-2/3 pr-8">
        <h2 className="text-6xl font-semibold mb-1 leading-tight">{translate('getToKnow', language)}</h2>
        <p className='text-6xl'>{translate('name', language)}</p>
        <p className="font-normal text-lg text-secondary mt-4">{translate('intro', language)}</p>
        <p className="font-normal text-lg text-secondary mt-4">{translate('goal', language)}</p>
      </div>
      <div className="w-1/3">
        <img src={profilePhoto} alt="Profile" className="w-[445px] h-[430px] object-cover" />
      </div>
      <div className="w-full flex mt-16">
        <div className="w-1/2">
          <p className="font-medium text-lg underline">{translate('mySkills', language)}</p>
          <p className="font-normal text-base mt-2">Java, Python, JavaScript, PHP, C++.</p>
        </div>
        <div className="w-1/2">
          <p className="font-medium text-lg underline">{translate('tools', language)}</p>
          <p className="font-normal text-base mt-2">React, Angular, Node.js, MySQL, Spring Boot, Laravel, Flask, Tailwind CSS, Git, Docker, MySQL, Kubernetes, Google Cloud Platform (GCP), PostgreSQL, Linux, Apache, Nginx.</p>
        </div>
      </div>
    </div>
  );
}

export default About;
