import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { LanguageProvider } from './context/LanguageContext';
import NavigationBar from './components/navigation-bar';
import Headline from './components/headline';
import ProjectsContainer from './components/project/ProjectContainer';
import About from './components/about';
import Article from './components/article';
import SecureCode from './components/article/secureCode'; 
import LanguageSwitcher from './components/languageSwitcher/LanguageSwitcher';

import { motion, AnimatePresence } from 'framer-motion';

function App() {
  const location = useLocation(); 

  return (
    <LanguageProvider>
      <div>
        <NavigationBar />
        <LanguageSwitcher />

        <AnimatePresence wait>
          <Routes location={location} key={location.pathname}>
            <Route
              path="/"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Headline />
                  <ProjectsContainer />
                </motion.div>
              }
            />
            <Route
              path="/about"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <About />
                </motion.div>
              }
            />
            <Route
              path="/article"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <Article />
                </motion.div>
              }
            />
            <Route
              path="/article/secureCode"
              element={
                <motion.div
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 1 }}
                  transition={{ duration: 0.5 }}
                >
                  <SecureCode />
                </motion.div>
              }
            />
          </Routes>
        </AnimatePresence>
      </div>
    </LanguageProvider>
  );
}

function AppWrapper() {
  return (
    <Router> 
      <App />
    </Router>
  );
}

export default AppWrapper;
